/* jQuery Countdown styles 2.0.0. */
.is-countdown {
	border: 1px solid #ccc;
	background-color: #eee;
}
.countdown-rtl {
	direction: rtl;
}
.countdown-holding span {
	color: #888;
}
.countdown-row {
	clear: both;
	width: 100%;
	padding: 0px 2px;
	text-align: center;
}
.countdown-show1 .countdown-section {
	width: 98%;
}
.countdown-show2 .countdown-section {
	width: 48%;
}
.countdown-show3 .countdown-section {
	width: 32.5%;
}
.countdown-show4 .countdown-section {
	width: 24.5%;
}
.countdown-show5 .countdown-section {
	width: 19.5%;
}
.countdown-show6 .countdown-section {
	width: 16.25%;
}
.countdown-show7 .countdown-section {
	width: 14%;
}
.countdown-section {
	display: block;
	float: left;
	font-size: 75%;
	text-align: center;
}
.countdown-amount {
	font-size: 200%;
}
.countdown-period {
	display: block;
}
.countdown-descr {
	display: block;
	width: 100%;
}
